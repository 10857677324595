import React from 'react'
import PT from 'prop-types'
import Decimal from 'decimal.js'

export function addCommas(nStr) {
  nStr += ''
  const x = nStr.split('.')
  let x1 = x[0]
  const x2 = x.length > 1 ? '.' + x[1] : ''
  const rgx = /(\d+)(\d{3})/
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2')
  }
  return x1 + x2
}

export const parseWithMoneyFormat = (
  value,
  {
    fractionDigits = 2,
    isNotCents = false,
    unitsPosition = 'left',
    commaSeparatorOnThousands = true,
    withCurrency = true,
  } = {},
) => {
  if (isNaN(value)) return value
  const isNegative = value < 0
  const temp = isNotCents
    ? new Decimal(value).abs().toFixed(fractionDigits)
    : new Decimal(value).div(100).abs().toFixed(fractionDigits)
  const valueToShow = commaSeparatorOnThousands && temp >= 1000 ? addCommas(temp) : temp
  const unitsToShow = withCurrency ? '$' : ''
  return `${isNegative ? '-' : ''}${unitsPosition === 'left' ? unitsToShow : ''}${valueToShow}${
    unitsPosition !== 'left' ? unitsToShow : ''
  }`
}

const Money = ({
  value,
  defaultValue,
  fractionDigits,
  unitsPosition,
  withCurrency,
  commaSeparatorOnThousands,
  className,
  isNotCents,
}) => {
  if (!value && value !== 0) return <span className={className}>{defaultValue}</span>
  const formattedValue = parseWithMoneyFormat(value, {
    fractionDigits,
    isNotCents,
    unitsPosition,
    commaSeparatorOnThousands,
    withCurrency,
  })

  return <span className={className}>{formattedValue}</span>
}

Money.propTypes = {
  className: PT.string,
  commaSeparatorOnThousands: PT.bool,
  defaultValue: PT.oneOfType([PT.string, PT.number]),
  fractionDigits: PT.number,
  isNotCents: PT.bool,
  unitsPosition: PT.string,
  value: PT.number,
  withCurrency: PT.bool,
}

Money.defaultProps = {
  className: '',
  commaSeparatorOnThousands: true,
  defaultValue: '-',
  fractionDigits: 2,
  isNotCents: false,
  unitsPosition: 'left',
  value: null,
  withCurrency: true,
}

export default Money
